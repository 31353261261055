import React from "react";
import { Row, Col, Card } from "antd";

const LargeContainer = ({ children, title, hoverable }) => (
  <Row
    type="flex"
    justify="center"
    align="middle"
    style={{ height: "auto", padding: "0.3em" }}
  >
    <Col style={{ width: "100%", maxWidth: "3000px" }}>
      <Card
        bodyStyle={{ padding: "0.5rem" }}
        hoverable={hoverable}
        title={title}
        style={{ width: "auto", padding: "0.3em", cursor: "default" }}
        headStyle={{ fontSize: "1.5em" }}
      >
        {children}
      </Card>
    </Col>
  </Row>
);

export default LargeContainer;
