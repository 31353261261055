import React, { useState } from "react";

import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import ByCreatedAtOverview from "../../../components/overviews/request/tabs/byCreatedAt/ByCreatedAtOverview";
import ByIsCompleteOverview from "../../../components/overviews/request/tabs/byIsComplete/ByIsCompleteOverview";
import ByProblemSolverOverview from "../../../components/overviews/request/tabs/byProblemSolver/ByProblemSolverOverview";
import { useParams } from "react-router-dom";

const { TabPane } = Tabs;

const RequestsOverviewPage = ({ pageKey }) => {
  const [tabChanges, setTabChanges] = useState(0);
  const { t } = useTranslation();
  const { fullName } = useParams();

  return (
    <>
      <Tabs
        defaultActiveKey={pageKey}
        centered={true}
        onChange={() => setTabChanges(tabChanges + 1)}
      >
        <TabPane tab={t("RequestsOverviewPage.byCreatedAt")} key="1">
          <ByCreatedAtOverview changes={tabChanges} />
        </TabPane>
        <TabPane tab={t("RequestsOverviewPage.byIsComplete")} key="2">
          <ByIsCompleteOverview changes={tabChanges} />
        </TabPane>
        <TabPane tab={t("RequestsOverviewPage.byProblemSolver")} key="3">
          <ByProblemSolverOverview fullName={fullName} changes={tabChanges} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default RequestsOverviewPage;
