import { enableEditQueryString } from "./queryStrings";

export const homeRoute = "/";

//export const requestsRoute = "/requests";
export const problemSolversRoute = "/problemSolversRoute";

export const createRequestRoute = "/create/request";
export const createProblemSolverRoute = "/create/problemsolver";

export const profileRoute = "/profile";

export const requestDetailsRoute = `/details/request/:id`;
export const problemSolverDetailsRoute = `/details/problemSolver/:fullName`;

export const requestDetailsRouteWithQueryStrings = `/details/request/:id?${enableEditQueryString}=:${enableEditQueryString}`;
export const problemSolverDetailsRouteWithQueryStrings = `/details/problemSolver/:fullName?${enableEditQueryString}=:${enableEditQueryString}`;

//overview routes
export const requestsRoute = "/overview/requests";
export const requestByCreatedAtRoute = `/overview/requests/byCreatedAt`;
export const requestByIsCompleteRoute = `/overview/requests/byIsComplete`;
export const requestByProblemSolverRoute = `/overview/requests/byProblemSolver/:fullName?`;
