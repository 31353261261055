import * as problemSolverApiIntegration from "../problemsolver/problemSolverApiIntegration";

export const createProblemSolver = async (data) => {
  return problemSolverApiIntegration.createNewProblemSolver(data);
};

export const listProblemSolvers = async () => {
  return await problemSolverApiIntegration.listProblemSolvers();
};

export const getProblemSolver = async (fullName) => {
  return await problemSolverApiIntegration.getProblemSolver(fullName);
};

export const deleteProblemSolver = async (fullName) => {
  return await problemSolverApiIntegration.deleteProblemSolver(fullName);
};

export const updateProblemSolver = async (fullName, data) => {
  return await problemSolverApiIntegration.updateProblemSolver(fullName, data);
};
