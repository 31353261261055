import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as problemSolverService from "../../service/problemsolver/problemSolverService";

function useProblemSolver() {
  const { fullName: problemSolverId } = useParams("fullName");
  const [problemSolver, setProblemSolver] = useState();
  const [getProblemSolverLoading, setGetProblemSolverLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    const getProblemSolver = async () => {
      try {
        const problemSolver = await problemSolverService.getProblemSolver(
          problemSolverId
        );
        setProblemSolver(problemSolver);
      } finally {
        setGetProblemSolverLoading(false);
      }
    };
    getProblemSolver(problemSolverId);
  }, [problemSolverId]);

  const deleteProblemSolver = async () => {
    setDeleteLoading(true);
    try {
      const deletedProblemSolver = await problemSolverService.deleteProblemSolver(
        problemSolver.fullName
      );
      setProblemSolver(deletedProblemSolver);
      return deletedProblemSolver;
    } finally {
      setDeleteLoading(false);
    }
  };

  const updateProblemSolver = async (fullName, data) => {
    setUpdateLoading(true);
    try {
      const updatedProblemSolver = await problemSolverService.updateProblemSolver(
        fullName,
        data
      );
      setProblemSolver(updatedProblemSolver);
      return updatedProblemSolver;
    } finally {
      setUpdateLoading(false);
    }
  };

  return {
    problemSolver,
    getLoading: getProblemSolverLoading,
    deleteProblemSolver,
    deleteLoading,
    updateProblemSolver,
    updateLoading,
  };
}

export default useProblemSolver;
