import React, { useState } from "react";
import ProblemSolverOverview from "../../../components/overviews/problemSolver/problemSolverOverview";

const ProblemSolversOverviewPage = () => {
  return (
    <>
      <ProblemSolverOverview />
    </>
  );
};

export default ProblemSolversOverviewPage;
