import React from "react";
import ProblemSolverForm from "../../components/forms/problemSolver/ProblemSolverForm";
import { useTranslation } from "react-i18next";
import FormContainer from "../../components/layouts/containers/FormContainer";
import { useHistory } from "react-router-dom";
// import { requestDetailsRoute } from "../../config/routes";
import { problemSolverDetailsRoute } from "../../config/routes";
import { PageHeader } from "antd";
import fixerAvatar from "../../assets/avatars/fixer.png";
import LargeContainer from "../../components/layouts/containers/LargeContainer";
import * as problemSolverService from "../../service/problemsolver/problemSolverService";
import { successNotification } from "../../components/openNotification";

function CreateProblemSolverPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const submitForm = async (values) => {
    console.log("Values are: ", values);
    const createdProblemSolver = await problemSolverService.createProblemSolver(
      values
    );
    successNotification("Okay!", t("problemSolver.successfullyCreatedMessage"));
    history.push(
      problemSolverDetailsRoute.replace(
        ":fullName",
        createdProblemSolver.fullName
      )
    );
  };

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={t("ProblemSolverForm.createPageTitle")}
        subTitle={t("ProblemSolverForm.createPageDescription")}
        avatar={{ src: fixerAvatar }}
      />
      <LargeContainer hoverable={true}>
        <FormContainer title={t("ProblemSolverForm.createFormTitle")}>
          <ProblemSolverForm
            problemSolver={undefined}
            onSubmit={submitForm}
            disableAll={false}
          />
        </FormContainer>
      </LargeContainer>
    </>
  );
}

export default CreateProblemSolverPage;
