import React from "react";
import { Button, PageHeader } from "antd";
import {
  ReloadOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import fixerAvatar from "../../../assets/avatars/fixer.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createProblemSolverRoute } from "../../../config/routes";

const ProblemSolversOverviewPageHeader = ({ reload, listLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageHeader
      title={t("ProblemSolversOverviewPage.problemSolvers")}
      subTitle={t("ProblemSolversOverviewPage.problemSolversPageDescription")}
      avatar={{ src: fixerAvatar }}
      extra={[
        <Button
          key="1"
          icon={listLoading ? <LoadingOutlined /> : <ReloadOutlined />}
          onClick={reload}
        >
          {t("action.reload")}
        </Button>,
        <Button
          type="primary"
          key="2"
          icon={<PlusCircleOutlined />}
          onClick={() => history.push(createProblemSolverRoute)}
        >
          {t("action.add")}
        </Button>,
      ]}
    />
  );
};

export default ProblemSolversOverviewPageHeader;
