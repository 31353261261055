import { useState, useEffect } from "react";
import * as requestService from "../service/request/requestService";
import moment from "moment";

function useRequestsByCreatedAt() {
  const [requests, setRequests] = useState();
  const [listLoading, setListLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await listRequests();
    };
    loadData();
  }, []);

  const reload = async () => await listRequests();

  const listRequests = async () => {
    const end = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    const begin = moment()
      .add(-14, "days")
      .format("YYYY-MM-DD");
    const requests = await requestService.listRequestsByCreatedAt(begin, end);
    setRequests(requests);
    setListLoading(false);
  };

  const deleteRequest = async (id) => {
    setDeleteLoading(true);
    const deletedRequest = await requestService.deleteRequest(id);
    setRequests(requests.filter((request) => request.id !== deletedRequest.id));
    setDeleteLoading(false);
  };

  const updateRequest = async (id, data) => {
    setUpdateLoading(true);
    const updatedRequest = await requestService.updateRequest(id, data);
    let requestList = requests.filter(
      (request) => request.id !== updatedRequest.id
    );
    requestList.push(updatedRequest);
    setRequests(requestList);
    setUpdateLoading(false);
  };

  return {
    requests,
    deleteRequest,
    updateRequest,
    listLoading,
    deleteLoading,
    updateLoading,
    reload,
  };
}

export default useRequestsByCreatedAt;
