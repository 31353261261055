import * as requestApiIntegration from "./requestApiIntegration";

export const createRequest = async (data) => {
  return requestApiIntegration.createNewRequest(data);
};

export const listRequests = async () => {
  return await requestApiIntegration.listRequests();
};

export const getRequestsByProblemSolver = async (problemSolverId) => {
  return await requestApiIntegration.getRequestsByProblemSolver(
    problemSolverId
  );
};

export const listRequestsByCreatedAt = async (begin, end) => {
  return await requestApiIntegration.listRequestsByCreatedAt(begin, end);
};

export const listRequestsByIsComplete = async (isComplete) => {
  return await requestApiIntegration.listRequestsByIsComplete(isComplete);
};

export const getRequest = async (id) => {
  return await requestApiIntegration.getRequest(id);
};

export const deleteRequest = async (id) => {
  return await requestApiIntegration.deleteRequest(id);
};

export const updateRequest = async (id, data) => {
  return await requestApiIntegration.updateRequest(id, data);
};
