import React, { useState, useEffect, useContext } from "react";
import ProblemSolverForm from "../../../components/forms/problemSolver/ProblemSolverForm";
import ProblemSolverEmailForm  from '../../../components/forms/problemSolver/ProblemSolverEmailForm';

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeader, Button, Popconfirm } from "antd";
import ProblemSolverContext from "../../../context/problemSolver/ProblemSolverContext";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import useQuery from "../../../hooks/useQuery";
import {
  successNotification,
  infoNotification,
  errorNotification,
} from "../../../components/openNotification";
import { enableEditQueryString } from "../../../config/queryStrings";
import { problemSolversRoute } from "../../../config/routes";
import LargeContainer from "../../../components/layouts/containers/LargeContainer";
import FormContainer from "../../../components/layouts/containers/FormContainer";

function ProblemSolverDetailsPage() {
  const [enableEdit, setEnableEdit] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { problemSolver, updateProblemSolver, deleteProblemSolver, deleteLoading } = useContext(
    ProblemSolverContext
  );
  const query = useQuery();

  useEffect(() => {
    const enable = query.get(enableEditQueryString) === "true";
    setEnableEdit(enable);
  }, []);

  const submitForm = async (values) => {
    const updatedProblemSolver = await updateProblemSolver(problemSolver.fullName, values);
    if (!updatedProblemSolver.errors) {
      successNotification(
        "Okay!",
        t("ProblemSolverDetailsPage.successfullyUpdatedMessage")
      );
      setEnableEdit(false);
    } else {
      errorNotification(
        t("problem", t("ProblemSolverDetailsPage.errorUpdatingMessage"))
      );
    }
  };

  const deleteTheProblemSolver = async () => {
    await deleteProblemSolver();
    infoNotification("Okay!", t("ProblemSolverDetailsPage.deletedMessage"));
    history.push(problemSolversRoute);
  };

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={t("ProblemSolverDetailsPage.detailsPageTitle")}
        subTitle={t("ProblemSolverDetailsPage.detailsPageDescription")}
        extra={[
          <Button
            key="1"
            icon={<EditOutlined />}
            onClick={() => setEnableEdit(true)}
            disabled={enableEdit}
          >
            {t("action.edit")}
          </Button>,
          <Popconfirm
            key="2"
            title={t("askConfirmationMessage")}
            placement="topRight"
            onConfirm={() => deleteTheProblemSolver()}
            okText={t("ok")}
            cancelText={t("cancel")}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              disabled={!enableEdit || deleteLoading}
            >
              {t("action.delete")}
            </Button>
          </Popconfirm>,
        ]}
      />
      {problemSolver && (
        <>
          <LargeContainer hoverable={true}>
            <FormContainer title={t("ProblemSolverDetailsPage.problemSolverFormTitle")}>
              <ProblemSolverEmailForm
                problemSolver={problemSolver}
                onSubmit={submitForm}
                disableAll={!enableEdit || deleteLoading}
              />
            </FormContainer>
          </LargeContainer>
        </>
      )}
    </>
  );
}

export default ProblemSolverDetailsPage;
