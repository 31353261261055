import React from "react";
import RequestContext from "./RequestContext";
import useRequest from "../../hooks/useRequest";

const RequestContextProvider = ({ children }) => {
  const {
    request,
    getLoading,
    deleteRequest,
    deleteLoading,
    updateRequest,
    updateLoading,
  } = useRequest();

  return (
    <RequestContext.Provider
      value={{
        request: request,
        getLoading: getLoading,
        deleteRequest: deleteRequest,
        deleteLoading: deleteLoading,
        updateRequest: updateRequest,
        updateLoading: updateLoading,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export default RequestContextProvider;
