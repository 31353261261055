import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import LargeContainer from "../components/layouts/containers/LargeContainer";
import LoadingSpinner from "../components/LoadingSpinner";
import { useTranslation } from "react-i18next";

function ProfilePage() {
  const [user, setUser] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    checkUserInfo();
  }, []);

  async function checkUserInfo() {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes };
      setUser(userInfo);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  if (!user) return <LoadingSpinner />;
  return (
    <LargeContainer>
      <b>
        <h1>{t("ProfilePage.profile")}</h1>
      </b>
      <h2>
        {t("ProfilePage.username")}: {user.username}
      </h2>
      <h3>
        {t("ProfilePage.email")}: {user.email}
      </h3>
      <h4>
        {t("ProfilePage.phone")}: {user.phone_number}
      </h4>
    </LargeContainer>
  );
}

export default ProfilePage;
