import React from "react";

import { Menu } from "antd";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  UnorderedListOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  homeRoute,
  requestsRoute,
  problemSolversRoute,
  createRequestRoute,
  createProblemSolverRoute,
  profileRoute,
} from "../../config/routes";

const { SubMenu } = Menu;

const SideNavigationMenu = ({ collapsed }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const menuConfig = [
    {
      name: t("menu.overviewRequest.overviewRequest"),
      icon: <UnorderedListOutlined />,
      url: requestsRoute,
    },
    {
      name: t("menu.overviewProblemSolver.overviewProblemSolver"),
      icon: <UnorderedListOutlined />,
      url: problemSolversRoute,
    },
    {
      name: t("menu.createRequest.createRequest"),
      icon: <PlusCircleOutlined />,
      url: createRequestRoute,
    },
    {
      name: t("menu.createProblemSolver.createProblemSolver"),
      icon: <PlusCircleOutlined />,
      url: createProblemSolverRoute,
    },
    {
      name: t("menu.profile.profile"),
      icon: <UserOutlined />,
      url: profileRoute,
    },
  ];

  const renderMenuItem = (item) => {
    return (
      <Menu.Item key={item.url} icon={item.icon}>
        <Link to={item.url}>{item.name}</Link>
      </Menu.Item>
    );
  };

  const renderSubMenu = (item) => {
    return (
      <SubMenu key={item.name} title={item.name} icon={item.icon}>
        {item.subItems.map((subItem) =>
          subItem.subItems ? (
            renderSubMenu(subItem)
          ) : (
            <Menu.Item key={subItem.url}>
              <Link to={subItem.url}>{subItem.name}</Link>
            </Menu.Item>
          )
        )}
      </SubMenu>
    );
  };

  return (
    <>
      <div className="logo" />
      <Menu
        data-testid="sideMenu"
        mode="inline"
        theme="dark"
        selectedKeys={[location.pathname]}
      >
        {menuConfig.map((item) =>
          item.subItems ? renderSubMenu(item) : renderMenuItem(item)
        )}
      </Menu>
    </>
  );
};

export default SideNavigationMenu;
