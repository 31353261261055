import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as requestService from "../service/request/requestService";

function useRequest() {
  const { id: requestId } = useParams("id");
  const [request, setRequest] = useState();
  const [getRequestLoading, setGetRequestLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    const getRequest = async () => {
      try {
        const request = await requestService.getRequest(requestId);
        setRequest(request);
      } finally {
        setGetRequestLoading(false);
      }
    };
    getRequest(requestId);
  }, [requestId]);

  const deleteRequest = async () => {
    setDeleteLoading(true);
    try {
      const deletedRequest = await requestService.deleteRequest(request.id);
      setRequest(deletedRequest);
      return deletedRequest;
    } finally {
      setDeleteLoading(false);
    }
  };

  const updateRequest = async (id, data) => {
    setUpdateLoading(true);
    try {
      const updatedRequest = await requestService.updateRequest(id, data);
      setRequest(updatedRequest);
      return updatedRequest;
    } finally {
      setUpdateLoading(false);
    }
  };

  return {
    request,
    getLoading: getRequestLoading,
    deleteRequest,
    deleteLoading,
    updateRequest,
    updateLoading,
  };
}

export default useRequest;
