import React from "react";
import { Modal, Form } from "antd";
import CreateProblemSolverModalForm from "./CreateProblemSolverModalForm";

const CreateFixerModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Create a new problemSolver"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            form.resetFields();
            await onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      data-testid="locationModalButtons"
    >
      <CreateProblemSolverModalForm form={form} />
    </Modal>
  );
};

export default CreateFixerModal;
