import React from "react";
import RequestForm from "../../components/forms/request/RequestForm";
import { useTranslation } from "react-i18next";
import FormContainer from "../../components/layouts/containers/FormContainer";
import { useHistory } from "react-router-dom";
import * as requestService from "../../service/request/requestService";
import { PageHeader } from "antd";
import { successNotification } from "../../components/openNotification";
import requestsAvatar from "../../assets/avatars/request.png";
import LargeContainer from "../../components/layouts/containers/LargeContainer";
import { requestDetailsRoute } from "../../config/routes";

function CreateRequestPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const submitForm = async (values) => {
    console.log(values);
    const createdRequest = await requestService.createRequest(values);
    successNotification("Okay!", t("request.successfullyCreatedMessage"));
    history.push(requestDetailsRoute.replace(":id", createdRequest.id));
  };
  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={t("request.createPageTitle")}
        subTitle={t("request.createPageDescription")}
        avatar={{ src: requestsAvatar }}
      />
      <LargeContainer hoverable={true}>
        <FormContainer title={t("request.createFormTitle")}>
          <RequestForm
            request={undefined}
            onSubmit={submitForm}
            disableAll={false}
          />
        </FormContainer>
      </LargeContainer>
    </>
  );
}

export default CreateRequestPage;
