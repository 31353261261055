/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      owner
      createdAt
      updatedAt
      referenceNumber
      title
      requestDescription
      actionsDescription
      status
      isComplete
      requestor
      requestorPhoneNumber
      requestorEmail
      requestProblemSolverId
      attachment
      attachments
      problemSolver {
        fullName
        phoneNumber
        email
        createdAt
        updatedAt
        requests {
          nextToken
        }
        owner
      }
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        referenceNumber
        title
        requestDescription
        actionsDescription
        status
        isComplete
        requestor
        requestorPhoneNumber
        requestorEmail
        requestProblemSolverId
        attachment
        attachments
        problemSolver {
          fullName
          phoneNumber
          email
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listRequestsByCreatedAt = /* GraphQL */ `
  query ListRequestsByCreatedAt(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByCreatedAt(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        referenceNumber
        title
        requestDescription
        actionsDescription
        status
        isComplete
        requestor
        requestorPhoneNumber
        requestorEmail
        requestProblemSolverId
        attachment
        attachments
        problemSolver {
          fullName
          phoneNumber
          email
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listRequestsByIsComplete = /* GraphQL */ `
  query ListRequestsByIsComplete(
    $owner: String
    $isComplete: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByIsComplete(
      owner: $owner
      isComplete: $isComplete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        referenceNumber
        title
        requestDescription
        actionsDescription
        status
        isComplete
        requestor
        requestorPhoneNumber
        requestorEmail
        requestProblemSolverId
        attachment
        attachments
        problemSolver {
          fullName
          phoneNumber
          email
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getRequestsByReferenceNumber = /* GraphQL */ `
  query GetRequestsByReferenceNumber(
    $owner: String
    $referenceNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRequestsByReferenceNumber(
      owner: $owner
      referenceNumber: $referenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        referenceNumber
        title
        requestDescription
        actionsDescription
        status
        isComplete
        requestor
        requestorPhoneNumber
        requestorEmail
        requestProblemSolverId
        attachment
        attachments
        problemSolver {
          fullName
          phoneNumber
          email
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getRequestsByProblemSolver = /* GraphQL */ `
  query GetRequestsByProblemSolver(
    $requestProblemSolverId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRequestsByProblemSolver(
      requestProblemSolverId: $requestProblemSolverId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        referenceNumber
        title
        requestDescription
        actionsDescription
        status
        isComplete
        requestor
        requestorPhoneNumber
        requestorEmail
        requestProblemSolverId
        attachment
        attachments
        problemSolver {
          fullName
          phoneNumber
          email
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getProblemSolver = /* GraphQL */ `
  query GetProblemSolver($fullName: String!) {
    getProblemSolver(fullName: $fullName) {
      fullName
      phoneNumber
      email
      createdAt
      updatedAt
      requests {
        items {
          id
          owner
          createdAt
          updatedAt
          referenceNumber
          title
          requestDescription
          actionsDescription
          status
          isComplete
          requestor
          requestorPhoneNumber
          requestorEmail
          requestProblemSolverId
          attachment
          attachments
        }
        nextToken
      }
      owner
    }
  }
`;
export const listProblemSolvers = /* GraphQL */ `
  query ListProblemSolvers(
    $fullName: String
    $filter: ModelProblemSolverFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProblemSolvers(
      fullName: $fullName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        fullName
        phoneNumber
        email
        createdAt
        updatedAt
        requests {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
