import React, { Suspense } from "react";

import ReactDOM from "react-dom";

import "./index.css";

import "./config/i18n";

import App from "./App";

import "antd/dist/antd.css";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

ReactDOM.render(
  // <Suspense fallback={<LoadingSpinner />}>
  <Suspense fallback={<> </>}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
