import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import {
  errorNotification,
  infoNotification,
} from "../../../../openNotification";
import { useTranslation } from "react-i18next";
import RequestActionButtons from "../../RequestActionButtons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  requestDetailsRoute,
  requestDetailsRouteWithQueryStrings,
} from "../../../../../config/routes";
import { useHistory } from "react-router-dom";
import { enableEditQueryString } from "../../../../../config/queryStrings";
import StatusInfo from "../parts/StatusInfo";

const ByIsCompleteTable = ({
  requests,
  updateRequest,
  deleteRequest,
  deleteLoading,
}) => {
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (requests) {
      setTableData(requests);
    }
  }, [requests]);

  const columns = [
    {
      title: t("RequestsOverviewPage.createdAt"),
      render: (text, record) => (
        <div
          style={{
            width: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {record.createdAt && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                className={"fas fa-camera fa-lg"}
                icon={faCalendar}
                style={{ paddingRight: "5px" }}
              />
              <div>{moment(record.createdAt).format("YYYY/MM/DD")}</div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      render: (text, record) => (
        <StatusInfo requestData={record} updateRequest={updateRequest} />
      ),
    },
    {
      title: t("RequestsOverviewPage.title"),
      dataIndex: "title",
    },
    {
      title: t("RequestsOverviewPage.requestor"),
      dataIndex: "requestor",
    },
    {
      title: t("RequestsOverviewPage.requestProblemSolverId"),
      dataIndex: "requestProblemSolverId",
    },
    {
      title: t("action.action"),
      key: "action",
      render: (text, record) => (
        <RequestActionButtons
          deleteItem={() => deleteItem(record)}
          deleteLoading={deleteLoading}
          editItem={() => {
            editItem(record);
          }}
          viewDetailsOfItem={() => {
            viewDetailsOfItem(record);
          }}
        />
      ),
    },
  ];

  const displayNotImplemented = () => {
    infoNotification("Info", t("notImplementedMessage"));
  };

  const editItem = async (rowRequestRecord) => {
    history.push(
      requestDetailsRouteWithQueryStrings
        .replace(":id", rowRequestRecord.id)
        .replace(`:${enableEditQueryString}`, "true")
    );
  };

  const viewDetailsOfItem = async (rowRequestRecord) => {
    history.push(requestDetailsRoute.replace(":id", rowRequestRecord.id));
  };

  const deleteItem = async (record) => {
    try {
      await deleteRequest(record.id);
      infoNotification("Info", t("RequestsOverviewPage.deletedMessage"));
    } catch (err) {
      console.error(err);
      errorNotification(
        t("problem"),
        t("RequestsOverviewPage.errorDeletingMessage")
      );
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey={(record) => record.createdAt}
        scroll={{ x: 800 }}
        pagination={{ position: "bottomLeft" }}
      />
    </>
  );
};

export default ByIsCompleteTable;
