import React from "react";
import { Result, Button } from "antd";

import { homeRoute } from "../config/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" data-testid="backHomeButton">
          <Link to={homeRoute}>{t("navigation.goBackHome")}</Link>
        </Button>
      }
    />
  );
};

export default NotFoundPage;
