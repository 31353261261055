import { API, Auth } from "aws-amplify";

import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

export const getRequest = async (id) => {
  try {
    const result = await API.graphql({
      query: queries.getRequest,
      variables: { id },
    });
    const request = result.data.getRequest;

    return request;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const listRequests = async () => {
  try {
    const result = await API.graphql({
      query: queries.listRequests,
    });
    const requests = result.data.listRequests.items;

    return requests;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const listProblemSolvers = async () => {
  try {
    const result = await API.graphql({
      query: queries.listProblemSolvers,
    });
    const requests = result.data.listProblemSolvers.items;

    return requests;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const getRequestsByProblemSolver = async (problemSolverId) => {
  try {
    const result = await API.graphql({
      query: queries.getRequestsByProblemSolver,
      variables: {
        requestProblemSolverId: problemSolverId,
        sortDirection: "DESC",
      },
    });
    const requests = result.data.getRequestsByProblemSolver.items;

    return requests;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const listRequestsByCreatedAt = async (begin, end) => {
  const currentUserInfo = await Auth.currentUserInfo();

  try {
    const result = await API.graphql({
      query: queries.listRequestsByCreatedAt,
      variables: {
        createdAt: { between: [begin, end] },
        owner: currentUserInfo.username,
        sortDirection: "DESC",
      },
    });
    const requests = result.data.listRequestsByCreatedAt.items;

    return requests;
  } catch (err) {
    console.error("err: ", err);
  }
};
export const listRequestsByIsComplete = async (isComplete) => {
  const currentUserInfo = await Auth.currentUserInfo();

  let marker = "y";
  if (isComplete) {
    marker = "y";
  } else if (!isComplete) {
    marker = "n";
  }

  try {
    const result = await API.graphql({
      query: queries.listRequestsByIsComplete,
      variables: {
        isComplete: { eq: marker },
        owner: currentUserInfo.username,
        sortDirection: "DESC",
      },
    });
    const requests = result.data.listRequestsByIsComplete.items;

    return requests;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const createNewRequest = async (data) => {
  try {
    const result = await API.graphql({
      query: mutations.createRequest,
      variables: { input: data },
    });
    const request = result.data.createRequest;

    return request;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const deleteRequest = async (id) => {
  try {
    const result = await API.graphql({
      query: mutations.deleteRequest,
      variables: { input: { id: id } },
    });
    const deletedRequest = result.data.deleteRequest;
    return deletedRequest;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const updateRequest = async (id, data) => {
  try {
    const result = await API.graphql({
      query: mutations.updateRequest,
      variables: { input: { id: id, ...data } },
    });
    const updatedRequest = result.data.updateRequest;
    return updatedRequest;
  } catch (err) {
    console.error("err: ", err);
  }
};
