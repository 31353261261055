import React, { useEffect, useState } from "react";
import useRequestsByProblemSolver from "../../../../../hooks/useRequestsByProblemSolver";
import ByProblemSolverOverviewPageHeader from "./ByProblemSolverOverviewPageHeader";
import ByProblemSolverTable from "./ByProblemSolverTable";
import { listProblemSolvers } from "../../../../../service/problemsolver/problemSolverService";

const ByProblemSolverOverview = ({ changes, fullName }) => {
  const [problemSolverId, setProblemSolverId] = useState();
  const [problemSolvers, setProblemSolvers] = useState([]);

  const {
    requests,
    reload,
    listLoading,
    deleteRequest,
    deleteLoading,
    updateRequest,
  } = useRequestsByProblemSolver(problemSolverId);

  useEffect(() => {
    const fetchProblemSolvers = async () => {
      const solvers = await listProblemSolvers();
      setProblemSolvers(solvers);
    };
    fetchProblemSolvers();
  }, []);

  useEffect(() => {
    if (changes > 1) {
      reload();
    }
  }, [changes]);

  return (
    <>
      <ByProblemSolverOverviewPageHeader
        listLoading={listLoading}
        setProblemSolverId={setProblemSolverId}
        problemSolvers={problemSolvers}
        defaultName={fullName}
      />
      <ByProblemSolverTable
        requests={requests}
        deleteRequest={deleteRequest}
        deleteLoading={deleteLoading}
        updateRequest={updateRequest}
      />
    </>
  );
};

export default ByProblemSolverOverview;
