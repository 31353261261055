import React from "react";
import { Popover, Button } from "antd";

function IconDropdown({ menu, icon, visible, onVisibleChange }) {
  return (
    <Popover
      placement="bottomRight"
      content={menu}
      trigger="click"
      style={{ height: 100, overflowY: "scroll" }}
      overlayStyle={{ maxWidth: "50vw" }}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <Button shape="circle" icon={icon} size={"large"} />
    </Popover>
  );
}

export default IconDropdown;
