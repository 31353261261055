import React, { useEffect } from "react";
import useRequestsByIsComplete from "../../../../../hooks/useRequestsByIsComplete";
import RequestsOverviewPageHeader from "../../../../../pages/overview/request/RequestsOverviewPageHeader";
import ByIsCompleteTable from "./ByIsCompleteTable";

const ByIsCompleteOverview = ({ changes }) => {
  const {
    requests,
    reload,
    listLoading,
    deleteRequest,
    deleteLoading,
    updateRequest,
  } = useRequestsByIsComplete();

  useEffect(() => {
    if (changes > 1) {
      reload();
    }
  }, [changes]);

  return (
    <>
      <RequestsOverviewPageHeader reload={reload} listLoading={listLoading} />
      <ByIsCompleteTable
        requests={requests}
        deleteRequest={deleteRequest}
        deleteLoading={deleteLoading}
        updateRequest={updateRequest}
      />
    </>
  );
};

export default ByIsCompleteOverview;
