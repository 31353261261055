import React, { useEffect } from "react";
import useProblemSolvers from "../../../hooks/problemSolverHooks/useProblemSolvers";
import ProblemSolverTable from "./ProblemSolverTable";
import ProblemSolversOverviewPageHeader from './ProblemSolversOverviewPageHeader';

const ProblemSolverOverview = ({ changes }) => {
  const {
    problemSolvers,
    reload,
    listLoading,
    updateProblemSolver,
    deleteProblemSolver,
    deleteLoading,
  } = useProblemSolvers();

  useEffect(() => {
    if (changes > 1) {
      reload();
    }
  }, [changes]);

  return (
    <>
      <ProblemSolversOverviewPageHeader reload={reload} listLoading={listLoading} />
      <ProblemSolverTable
        problemSolvers={problemSolvers}
        deleteProblemSolver={deleteProblemSolver}
        deleteLoading={deleteLoading}
        updateProblemSolver={updateProblemSolver}
      />
    </>
  );
};

export default ProblemSolverOverview;
