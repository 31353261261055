/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      referenceNumber
      title
      requestDescription
      actionsDescription
      status
      isComplete
      requestor
      requestorPhoneNumber
      requestorEmail
      requestProblemSolverId
      attachment
      attachments
      problemSolver {
        fullName
        phoneNumber
        email
        createdAt
        updatedAt
        requests {
          nextToken
        }
        owner
      }
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      referenceNumber
      title
      requestDescription
      actionsDescription
      status
      isComplete
      requestor
      requestorPhoneNumber
      requestorEmail
      requestProblemSolverId
      attachment
      attachments
      problemSolver {
        fullName
        phoneNumber
        email
        createdAt
        updatedAt
        requests {
          nextToken
        }
        owner
      }
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      referenceNumber
      title
      requestDescription
      actionsDescription
      status
      isComplete
      requestor
      requestorPhoneNumber
      requestorEmail
      requestProblemSolverId
      attachment
      attachments
      problemSolver {
        fullName
        phoneNumber
        email
        createdAt
        updatedAt
        requests {
          nextToken
        }
        owner
      }
    }
  }
`;
export const createProblemSolver = /* GraphQL */ `
  mutation CreateProblemSolver(
    $input: CreateProblemSolverInput!
    $condition: ModelProblemSolverConditionInput
  ) {
    createProblemSolver(input: $input, condition: $condition) {
      fullName
      phoneNumber
      email
      createdAt
      updatedAt
      requests {
        items {
          id
          owner
          createdAt
          updatedAt
          referenceNumber
          title
          requestDescription
          actionsDescription
          status
          isComplete
          requestor
          requestorPhoneNumber
          requestorEmail
          requestProblemSolverId
          attachment
          attachments
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateProblemSolver = /* GraphQL */ `
  mutation UpdateProblemSolver(
    $input: UpdateProblemSolverInput!
    $condition: ModelProblemSolverConditionInput
  ) {
    updateProblemSolver(input: $input, condition: $condition) {
      fullName
      phoneNumber
      email
      createdAt
      updatedAt
      requests {
        items {
          id
          owner
          createdAt
          updatedAt
          referenceNumber
          title
          requestDescription
          actionsDescription
          status
          isComplete
          requestor
          requestorPhoneNumber
          requestorEmail
          requestProblemSolverId
          attachment
          attachments
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteProblemSolver = /* GraphQL */ `
  mutation DeleteProblemSolver(
    $input: DeleteProblemSolverInput!
    $condition: ModelProblemSolverConditionInput
  ) {
    deleteProblemSolver(input: $input, condition: $condition) {
      fullName
      phoneNumber
      email
      createdAt
      updatedAt
      requests {
        items {
          id
          owner
          createdAt
          updatedAt
          referenceNumber
          title
          requestDescription
          actionsDescription
          status
          isComplete
          requestor
          requestorPhoneNumber
          requestorEmail
          requestProblemSolverId
          attachment
          attachments
        }
        nextToken
      }
      owner
    }
  }
`;
