import React from "react";
import { Button, PageHeader } from "antd";
import {
  ReloadOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import requestsAvatar from "../../../assets/avatars/request.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createRequestRoute } from "../../../config/routes";

const RequestsOverviewPageHeader = ({ reload, listLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageHeader
      title={t("RequestsOverviewPage.requests")}
      subTitle={t("RequestsOverviewPage.requestsPageDescription")}
      avatar={{ src: requestsAvatar }}
      extra={[
        <Button
          key="1"
          icon={listLoading ? <LoadingOutlined /> : <ReloadOutlined />}
          onClick={reload}
        >
          {t("action.reload")}
        </Button>,
        <Button
          type="primary"
          key="2"
          icon={<PlusCircleOutlined />}
          onClick={() => history.push(createRequestRoute)}
        >
          {t("action.add")}
        </Button>,
      ]}
    />
  );
};

export default RequestsOverviewPageHeader;
