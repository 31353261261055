import { API, Auth } from "aws-amplify";

import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

export const getProblemSolver = async (fullName) => {
  try {
    const result = await API.graphql({
      query: queries.getProblemSolver,
      variables: { fullName },
    });
    const problemSolver = result.data.getProblemSolver;
    console.log(result);
    return problemSolver;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const listProblemSolvers = async () => {
  try {
    const result = await API.graphql({
      query: queries.listProblemSolvers,
    });
    const problemSolvers = result.data.listProblemSolvers.items;

    return problemSolvers;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const createNewProblemSolver = async (data) => {
  try {
    const result = await API.graphql({
      query: mutations.createProblemSolver,
      variables: { input: data },
    });
    const problemSolvers = result.data.createProblemSolver;

    return problemSolvers;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const deleteProblemSolver = async (fullName) => {
  try {
    const result = await API.graphql({
      query: mutations.deleteProblemSolver,
      variables: { input: { fullName: fullName } },
    });
    const deletedProblemSolvers = result.data.deleteProblemSolver;
    return deletedProblemSolvers;
  } catch (err) {
    console.error("err: ", err);
  }
};

export const updateProblemSolver = async (fullName, data) => {
  try {
    const result = await API.graphql({
      query: mutations.updateProblemSolver,
      variables: { input: { fullName: fullName, ...data } },
    });
    const updatedProblemSolver = result.data.updateProblemSolver;
    return updatedProblemSolver;
  } catch (err) {
    console.error("err: ", err);
  }
};
