import React, { useState } from "react";

import { Button } from "antd";
import IconDropdown from "./IconDropdown";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";

function LanguageDropdown() {
  const [clicked, setClicked] = useState();
  const { i18n } = useTranslation();

  const changeLanguageToNL = () => {
    i18n.changeLanguage("nl");
    setClicked(false);
  };
  const changeLanguageToEN = () => {
    i18n.changeLanguage("en");
    setClicked(false);
  };

  const handleClickChange = (visible) => {
    setClicked(visible);
  };

  return (
    <IconDropdown
      visible={clicked}
      onVisibleChange={handleClickChange}
      menu={
        <div
          className="lng"
          style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
        >
          <Button
            shape="circle"
            className={i18n.languages[0] === "en" ? "selected" : "notSelected"}
            onClick={changeLanguageToEN}
          >
            EN
          </Button>
          <Button
            shape="circle"
            className={i18n.languages[0] === "nl" ? "selected" : "notSelected"}
            onClick={changeLanguageToNL}
          >
            NL
          </Button>
        </div>
      }
      icon={<GlobalOutlined />}
    />
  );
}

export default LanguageDropdown;
