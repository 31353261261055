import React, { useState, useEffect } from "react";

import RequestStatus from "../../../../../domain/RequestStatus";
import { Select } from "antd";

import "./StatusInfo.scss";

const StatusInfo = ({ requestData, updateRequest }) => {
  const options = [
    { value: RequestStatus.CREATED },
    { value: RequestStatus.ASSIGNED },
    { value: RequestStatus.COMPLETED },
    { value: RequestStatus.INPROGRESS },
  ];

  const handleChange = async (value) => {
    if (value === RequestStatus.COMPLETED) {
      await updateRequest(requestData.id, {
        status: value,
        isComplete: "y",
        createdAt: requestData.createdAt,
      });
    } else {
      await updateRequest(requestData.id, {
        status: value,
        isComplete: "n",
        createdAt: requestData.createdAt,
      });
    }
  };

  return (
    <div
      style={{
        // width: "auto",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        gap: "1rem",
      }}
    >
      <Select
        showArrow
        onChange={handleChange}
        value={requestData.status}
        style={{ width: 120 }}
        name="status"
        options={options}
        data-testid="selectStatus"
      >
        <Select.Option
          value={RequestStatus.CREATED}
          data-testid="selectUnplannedOption"
        >
          {RequestStatus.CREATED}
        </Select.Option>
        <Select.Option
          value={RequestStatus.ASSIGNED}
          data-testid="selectPlannedOption"
        >
          {RequestStatus.ASSIGNED}
        </Select.Option>
        <Select.Option
          value={RequestStatus.INPROGRESS}
          data-testid="selectInProgressOption"
        >
          {RequestStatus.INPROGRESS}
        </Select.Option>
        <Select.Option
          value={RequestStatus.COMPLETED}
          data-testid="selectCompleteOption"
        >
          {RequestStatus.COMPLETED}
        </Select.Option>
      </Select>
    </div>
  );
};

export default StatusInfo;
