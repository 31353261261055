/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect } from "react";

import { Form, Button, Input, Upload, message, Row, Col } from "antd";
import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { UploadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import RequestStatus from "../../../domain/RequestStatus";
import { Select } from "antd";
import CreateFixerModal from "./CreateFixerModal";
import * as problemSolverService from "../../../service/problemsolver/problemSolverService";
import { successNotification } from "../../openNotification";

const NEW_ITEM = "NEW_ITEM";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const RequestForm = ({ request, onSubmit, disableAll }) => {
  const [attachments, setAttachments] = useState([]);
  const [problemSolvers, setProblemSolvers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState({
    title: request?.title,
    requestDescription: request?.requestDescription,
    requestor: request?.requestor,
    requestorPhoneNumber: request?.requestorPhoneNumber,
    requestProblemSolverId: request?.requestProblemSolverId,
    requestorEmail: request?.requestorEmail,
    actionsDescription: request?.actionsDescription,
    status: request?.status,
    isComplete: request?.isComplete === "y" ? true : false,
  });

  const fetchProblemSolvers = async () => {
    const solvers = await problemSolverService.listProblemSolvers();
    setProblemSolvers(solvers);
  };

  useEffect(() => {
    fetchProblemSolvers();
  }, []);

  useEffect(() => {
    if (request) {
      if (request.attachments) {
        const getAttachmentsWithLinks = async () => {
          const incomingAttachments = JSON.parse(request.attachments);
          const attachmentList = [];
          await incomingAttachments.forEach(async (attachment) => {
            const attachmentUrl = await getSignedUrl(attachment.key);
            const newAttachment = {
              name: attachment.name,
              key: attachment.key,
              url: attachmentUrl,
            };
            attachmentList.push(newAttachment);
          });
          setAttachments(attachmentList);
        };
        getAttachmentsWithLinks();
      }
    }
  }, []);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const validateMessages = {
    required: t("validateMessages.required"),
    types: {
      email: t("validateMessages.types.email"),
      number: t("validateMessages.types.number"),
    },
    number: {
      range: t("validateMessages.number.range"),
    },
  };

  const submitFixerModalForm = async (values) => {
    console.log(values);
    const newProblemSolver = await problemSolverService.createProblemSolver(
      values
    );
    successNotification("Okay!", t("problemSolver.successfullyCreatedMessage"));
    setVisible(false);
    await setTimeout(500);
    await fetchProblemSolvers();
    const fields = form.getFieldsValue();
    fields.requestProblemSolverId = newProblemSolver.fullName;
    form.setFieldsValue(fields);
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const normFile = (e) => {
    console.log("Event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    const imageKey = uuid() + "-" + file.name.replace(/\s/g, "-").toLowerCase();
    const result = await Storage.put(imageKey, file);
    if (result.key) {
      onSuccess({ key: result.key });
    }
  };

  const submitForm = async (values) => {
    console.dir(values);

    let { upload, ...data } = values;

    const attachmentsWithoutUrl = attachments.map(({ url, ...item }) => item);
    data = {
      ...data,
      attachments: JSON.stringify(attachmentsWithoutUrl),
      isComplete: data.isComplete ? "y" : "n",
      status: data.requestProblemSolverId
        ? RequestStatus.ASSIGNED
        : RequestStatus.CREATED,
    };
    console.log(`data is ${JSON.stringify(data)}`);
    setDefaultFormValues(data);

    onSubmit(data);
    form.resetFields();
  };

  const getSignedUrl = async (key) => {
    return await Storage.get(key);
  };

  const uploadProps = {
    listType: "text",
    customRequest: dummyRequest,
    onChange: async (info) => {
      console.log("onChange:");
      console.log(info);
      switch (info.file.status) {
        case "uploading":
          console.log(info.file, info.fileList);
          break;
        case "done":
          console.log("done");
          const attachmentUrl = await getSignedUrl(info.file.response.key);
          const newAttachment = {
            name: info.file.name,
            key: info.file.response.key,
            url: attachmentUrl,
          };
          setAttachments([...attachments, newAttachment]);
          message.success(
            `${info.file.name}\n ${t("RequestForm.fileUploadSuccessfull")}`
          );
          break;
        case "removed":
          setAttachments(
            attachments.filter((attachment) => attachment.key !== info.file.key)
          );
          message.success(
            `${info.file.name}\n ${t("RequestForm.fileDeleteSuccessfull")}`
          );
          break;
        default:
          message.error(
            `${info.file.name}\n ${t("RequestForm.somethingWentWrongMessage")}`
          );
      }
    },
    onRemove: async (info) => {
      await Storage.remove(info.key);
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const onChangeList = (value) => {
    if (value === NEW_ITEM) {
      setVisible(true);
    }
  };

  return (
    <>
      <Form
        {...layout}
        labelAlign="left"
        form={form}
        initialValues={defaultFormValues}
        name="request"
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="title"
          label={t("request.title")}
          rules={[
            {
              required: true,
            },
          ]}
          colon={false}
        >
          <Input disabled={disableAll} />
        </Form.Item>

        <Form.Item
          name="requestDescription"
          label={t("request.requestDescription")}
          className="wrap-label"
          colon={false}
        >
          <Input.TextArea
            disabled={disableAll}
            showCount={true}
            maxLength={500}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Form.Item
          name="requestor"
          label={t("RequestForm.requestorName")}
          rules={[
            {
              required: true,
            },
          ]}
          colon={false}
        >
          <Input disabled={disableAll} />
        </Form.Item>

        <Form.Item
          name="requestorEmail"
          label={t("RequestForm.requestorEmail")}
          colon={false}
          className="wrap-label"
          validateTrigger="onSubmit"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input disabled={disableAll} />
        </Form.Item>

        <Form.Item
          name="requestorPhoneNumber"
          label={t("RequestForm.requestorPhoneNumber")}
          colon={false}
          className="wrap-label"
          style={{ display: "none" }}
        >
          <Input disabled={disableAll} />
        </Form.Item>

        <Form.Item
          name="requestProblemSolverId"
          className="wrap-label"
          label={t("RequestForm.problemSolver")}
          hasFeedback
          rules={[
            { required: true, message: "Please input problemSolver!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("requestProblemSolverId") === NEW_ITEM) {
                  return Promise.reject("Please select a problem solver");
                }
                return Promise.resolve();
              },
            }),
          ]}
          colon={false}
          data-testid="selectRequestProblemSolverId"
        >
          <Select
            key={visible}
            disabled={disableAll}
            allowClear
            showSearch
            placeholder="Naam van de oplosser"
            onChange={onChangeList}
            filterOption={(input, option) => {
              return option.value.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Select.Option value={NEW_ITEM}>
              <Button type="primary" size="small" icon={<PlusCircleOutlined />}>
                {t("RequestForm.newFixer")}
              </Button>
            </Select.Option>
            {problemSolvers &&
              problemSolvers.sort().map((locationName, index) => (
                <Select.Option key={index} value={locationName.fullName}>
                  {locationName.fullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <CreateFixerModal
          visible={visible}
          onCreate={submitFixerModalForm}
          onCancel={() => {
            setVisible(false);
          }}
        />

        <Form.Item
          name="actionsDescription"
          label={t("request.actionsDescription")}
          className="wrap-label"
          colon={false}
        >
          <Input.TextArea
            disabled={disableAll}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Form.Item
          name="upload"
          label={t("request.attachments")}
          getValueFromEvent={normFile}
          colon={false}
          extra={
            disableAll &&
            `${t("RequestForm.numberOfAttachments")}: ${
              JSON.parse(request?.attachments).length
            }`
          }
        >
          <Upload
            {...uploadProps}
            fileList={attachments}
            showUploadList={!disableAll}
            defaultFileList={attachments}
          >
            <Button icon={<UploadOutlined />} disabled={disableAll}>
              {t("RequestForm.uploadAttachement")}
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          wrapperCol={{ ...layout.wrapperCol, offset: layout.labelCol.span }}
        >
          <Button
            data-testid="formSubmitButton"
            type="primary"
            htmlType="submit"
            disabled={disableAll}
          >
            {t("action.save")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default RequestForm;
