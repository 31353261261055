import React, { useEffect, useState } from "react";
import { Table } from "antd";
import {
  errorNotification,
  infoNotification,
} from "../../openNotification";
import { useTranslation } from "react-i18next";
import {
  problemSolverDetailsRoute,
  problemSolverDetailsRouteWithQueryStrings,
} from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { enableEditQueryString } from "../../../config/queryStrings";
import ProblemSolverActionButtons from "./ProblemSolverActionButtons";

const ProblemSolverTable = ({
  problemSolvers,
  updateProblemSolver,
  deleteProblemSolver,
  deleteLoading,
}) => {
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (problemSolvers) {
      setTableData(problemSolvers);
    }
  }, [problemSolvers]);

  const columns = [
    {
      title: t("ProblemSolversOverviewPage.fullName"),
      dataIndex: "fullName",
    },
    {
      title: t("ProblemSolversOverviewPage.email"),
      dataIndex: "email",
    },
    {
      title: t("action.action"),
      key: "action",
      render: (text, record) => (
        <ProblemSolverActionButtons
          deleteItem={() => deleteItem(record)}
          deleteLoading={deleteLoading}
          editItem={() => {
            editItem(record);
          }}
          viewDetailsOfItem={() => {
            viewDetailsOfItem(record);
          }}
        />
      ),
    },
  ];

  const displayNotImplemented = () => {
    infoNotification("Info", t("notImplementedMessage"));
  };

  const editItem = async (rowProblemSolverRecord) => {
    history.push(
      problemSolverDetailsRouteWithQueryStrings
        .replace(":fullName", rowProblemSolverRecord.fullName)
        .replace(`:${enableEditQueryString}`, "true")
    );
  };

  const viewDetailsOfItem = async (rowProblemSolverRecord) => {
    history.push(problemSolverDetailsRoute.replace(":fullName", rowProblemSolverRecord.fullName));
  };

  const deleteItem = async (record) => {
    try {
      await deleteProblemSolver(record.fullName);
      infoNotification("Info", t("ProblemSolversOverviewPage.deletedMessage"));
    } catch (err) {
      console.error(err);
      errorNotification(
        t("problem"),
        t("ProblemSolversOverviewPage.errorDeletingMessage")
      );
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey={(record) => record.fullName}
        scroll={{ x: 800 }}
        pagination={{ position: "bottomLeft" }}
      />
    </>
  );
};

export default ProblemSolverTable;
