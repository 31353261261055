import React from 'react';
import ProblemSolverContext from '../problemSolver/ProblemSolverContext';
import useProblemSolver from '../../hooks/problemSolverHooks/useProblemSolver'; // probleem oplosser

const ProblemSolverContextProvider = ({children}) => {
  const {
    problemSolver,
    getLoading,
    deleteProblemSolver,
    deleteLoading,
    updateProblemSolver,
    updateLoading,
  } = useProblemSolver();

  return (
    <ProblemSolverContext.Provider
      value={{
        problemSolver: problemSolver,
        getLoading: getLoading,
        deleteProblemSolver: deleteProblemSolver,
        deleteLoading: deleteLoading,
        updateProblemSolver: updateProblemSolver,
        updateLoading: updateLoading,
      }}
    >
      {children}
    </ProblemSolverContext.Provider>
  );
};

export default ProblemSolverContextProvider;
