import React from "react";
import { Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

import { Button } from "antd";
import { useTranslation } from "react-i18next";

const ProblemSolverActionButtons = ({
  viewDetailsOfItem,
  editItem,
  deleteItem,
  deleteLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={viewDetailsOfItem}
        />
        <Button type="primary" icon={<EditOutlined />} onClick={editItem} />
        <Popconfirm
          title={t("askConfirmationMessage")}
          placement="topRight"
          onConfirm={deleteItem}
          okText={t("ok")}
          cancelText={t("cancel")}
        >
          <Button
            disabled={deleteLoading}
            danger
            type="primary"
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </div>
      <div style={{ display: "flex", gap: "0.5rem" }}></div>
    </div>
  );
};

export default ProblemSolverActionButtons;
