import React, { useState, useEffect } from "react";
import { Button, PageHeader, Select } from "antd";
import {
  ReloadOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import requestsAvatar from "../../../../../assets/avatars/request.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createRequestRoute } from "../../../../../config/routes";

const ByProblemSolverOverviewPageHeader = ({
  problemSolvers,
  setProblemSolverId,
  defaultName
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setProblemSolverId(defaultName);
  },[]);

  return (
    <PageHeader
      title={t("RequestsOverviewPage.requests")}
      subTitle={t("RequestsOverviewPage.requestsPageDescription")}
      avatar={{ src: requestsAvatar }}
      extra={[
        <Select
          key="0"
          onSelect={(name) => setProblemSolverId(name)}
          showSearch
          defaultValue={defaultName}
          placeholder="Naam van de oplosser"
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {problemSolvers &&
            problemSolvers.sort().map((locationName, index) => (
              <Select.Option key={index} value={locationName.fullName}>
                {locationName.fullName}
              </Select.Option>
            ))}
        </Select>,
        <Button
          type="primary"
          key="1"
          icon={<PlusCircleOutlined />}
          onClick={() => history.push(createRequestRoute)}
        >
          {t("action.add")}
        </Button>,
      ]}
    />
  );
};

export default ByProblemSolverOverviewPageHeader;
