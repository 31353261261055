import React from "react";
import { Row, Col, Card } from "antd";

const FormContainer = ({ children, title, hoverable = false }) => (
  <Row
    type="flex"
    justify="center"
    align="middle"
    style={{ height: "auto", padding: "0.3em" }}
  >
    <Col style={{ width: "100%", maxWidth: "700px" }}>
      <Card
        hoverable={hoverable}
        title={title}
        style={{ width: "auto", padding: "0.3em" }}
        headStyle={{ fontSize: "1.5em" }}
      >
        {children}
      </Card>
    </Col>
  </Row>
);

export default FormContainer;
