import React, { useState, useEffect } from "react";

import {
  AmplifyAuthenticator,
  AmplifyContainer,
  AmplifySignIn,
} from "@aws-amplify/ui-react";

import ApplicationRoutes from "./config/applicationRoutes/ApplicationRoutes";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import "./App.scss";

function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ApplicationRoutes />
  ) : (
    <AmplifyContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
      </AmplifyAuthenticator>
    </AmplifyContainer>
  );
}

export default App;
