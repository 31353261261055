import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import LanguageDropdown from "./content/LanguageDropdown";

import { LogoutOutlined } from "@ant-design/icons";

import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { AmplifySignOut } from "@aws-amplify/ui-react";

import "./ApplicationHeader.scss";
import SignOutDropDown from "./content/SignOutDropDown";

const ApplicationHeader = ({ collapsed, handleToggle }) => {
  let [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let updateUser = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch {
        setUser(null);
      }
    };
    Hub.listen("auth", updateUser); // listen for authevents
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, []);

  return (
    <Layout.Header
      style={{ padding: "0 0 0 1em" }}
      className="site-layout-background application-header"
    >
      <Button
        type="primary"
        className="trigger"
        data-test="collapse-button-header"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        size="large"
        onClick={handleToggle}
      />

      <div className="header-buttons">
        <LanguageDropdown />
        <SignOutDropDown
          menu={user ? <AmplifySignOut buttonText={t("signOut")} /> : null}
          icon={<LogoutOutlined />}
        />
      </div>
    </Layout.Header>
  );
};

export default ApplicationHeader;
