import { useState, useEffect } from "react";
import * as problemSolverService from "../../service/problemsolver/problemSolverService";

function useProblemSolvers() {
  const [problemSolvers, setProblemSolvers] = useState();
  const [listLoading, setListLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    listProblemSolvers();
  }, []);

  const reload = () => listProblemSolvers();

  const listProblemSolvers = async () => {
    const problemSolvers = await problemSolverService.listProblemSolvers();
    setProblemSolvers(problemSolvers);
    setListLoading(false);
  };

  const deleteProblemSolver = async (fullName) => {
    setDeleteLoading(true);
    const deletedProblemSolver = await problemSolverService.deleteProblemSolver(
      fullName
    );
    setProblemSolvers(
      problemSolvers.filter(
        (problemSolver) =>
          problemSolver.fullName !== deletedProblemSolver.fullName
      )
    );
    setDeleteLoading(false);
  };

  const updateProblemSolver = async (fullName, data) => {
    setUpdateLoading(true);
    const updatedProblemSolver = await problemSolverService.updateProblemSolver(
      fullName,
      data
    );
    let problemSolverList = problemSolvers.filter(
      (problemSolver) =>
        problemSolver.fullName !== updatedProblemSolver.fullName
    );
    problemSolverList.push(updatedProblemSolver);
    setProblemSolvers(problemSolverList);
    setUpdateLoading(false);
  };

  return {
    problemSolvers,
    deleteProblemSolver,
    updateProblemSolver,
    listLoading,
    deleteLoading,
    updateLoading,
    reload,
  };
}

export default useProblemSolvers;
