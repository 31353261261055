import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Layout } from "antd";

import SideNavigationMenu from "../../components/navigation/SideNavigationMenu";

import {
  homeRoute,
  requestsRoute,
  problemSolversRoute,
  createRequestRoute,
  profileRoute,
  requestDetailsRoute,
  problemSolverDetailsRoute,
  createProblemSolverRoute,
  requestByProblemSolverRoute,
  requestByCreatedAtRoute,
  requestByIsCompleteRoute,
} from "../routes";

import "./ApplicationRoutes.scss";
import NotFoundPage from "../../pages/NotFoundPage";
import ApplicationHeader from "../../components/layouts/applicationHeader/ApplicationHeader";
import CreateRequestPage from "../../pages/request/CreateRequestPage";
import ProfilePage from "../../pages/ProfilePage";
import RequestsOverviewPage from "../../pages/overview/request/RequestsOverviewPage";

import ProblemSolversOverviewPage from "../../pages/overview/problemSolver/ProblemSolversOverviewPage";

import RequestDetailsPage from "../../pages/details/request/RequestDetailsPage";
import RequestContextProvider from "../../context/request/RequestContextProvider";
import CreateProblemSolverPage from "../../pages/problemSolver/CreateProblemSolverPage";

import ProblemSolverDetailsPage from "../../pages/details/problemSolver/ProblemSolverDetailsPage";
import ProblemSolverContextProvider from "../../context/problemSolver/ProblemSolverContextProvider";

const { Sider, Content } = Layout;

const ApplicationRoutes = () => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    window.innerWidth <= 760 ? setCollapsed(true) : setCollapsed(false);
  }, []);

  const handleToggle = (event) => {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  };

  return (
    <Router>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={handleToggle}>
          <SideNavigationMenu collapsed={collapsed} />
        </Sider>
        <Layout className="site-layout">
          <ApplicationHeader
            collapsed={collapsed}
            handleToggle={handleToggle}
          />

          <div className="scrolling-wrapper">
            <div className="page-wrapper">
              <Content
                style={{
                  padding: "0 1rem 1rem 1rem",
                  minHeight: "100vh",
                  background: "#fff",
                }}
              >
                <Switch>
                  <Route exact path={homeRoute}>
                    <Redirect to={requestsRoute} from={homeRoute} />
                  </Route>
                  <Route exact path={requestsRoute}>
                    <RequestsOverviewPage />
                  </Route>
                  <Route exact path={requestByCreatedAtRoute}>
                    <RequestsOverviewPage activeTabKey="1" />
                  </Route>
                  <Route exact path={requestByIsCompleteRoute}>
                    <RequestsOverviewPage activeTabKey="2" />
                  </Route>
                  <Route exact path={requestByProblemSolverRoute}>
                    <RequestsOverviewPage activeTabKey="3" />
                  </Route>
                  <Route exact path={problemSolversRoute}>
                    <ProblemSolversOverviewPage />
                  </Route>
                  ;
                  <Route exact path={createRequestRoute}>
                    <CreateRequestPage />
                  </Route>
                  <Route exact path={createProblemSolverRoute}>
                    <CreateProblemSolverPage />
                  </Route>
                  <Route exact path={profileRoute}>
                    <ProfilePage />
                  </Route>
                  <Route path={requestDetailsRoute}>
                    <RequestContextProvider>
                      <RequestDetailsPage />
                    </RequestContextProvider>
                  </Route>
                  <Route path={problemSolverDetailsRoute}>
                    <ProblemSolverContextProvider>
                      <ProblemSolverDetailsPage />
                    </ProblemSolverContextProvider>
                  </Route>
                  <Route component={NotFoundPage} />
                </Switch>
              </Content>
            </div>
          </div>
        </Layout>
      </Layout>
    </Router>
  );
};

export default ApplicationRoutes;
