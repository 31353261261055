import { notification } from "antd";

export const successNotification = (
  message,
  description,
  placement = "bottomRight",
  duration = 3
) => {
  return notification.success({
    message: message,
    description: description,
    duration: duration,
    placement: placement,
    style: styles.notificationStyle,
  });
};

export const errorNotification = (
  message,
  description,
  placement = "bottomRight",
  duration = 3
) => {
  return notification.error({
    message: message,
    description: description,
    duration: duration,
    placement: placement,
    style: styles.notificationStyle,
  });
};

export const warningNotification = (
  message,
  description,
  placement = "bottomRight",
  duration = 3
) => {
  return notification.warning({
    message: message,
    description: description,
    duration: duration,
    placement: placement,
    style: styles.notificationStyle,
  });
};

export const infoNotification = (
  message,
  description,
  placement = "bottomRight",
  duration = 3
) => {
  return notification.info({
    message: message,
    description: description,
    duration: duration,
    placement: placement,
    style: styles.notificationStyle,
  });
};

const styles = {
  notificationStyle: {
    maxWidth: "80vw",
  },
};
