/* eslint-disable no-template-curly-in-string */

import React from "react";

import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const ProblemSolverForm = ({ problemSolver, onSubmit, disableAll }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const validateMessages = {
    required: t("validateMessages.required"),
    types: {
      email: t("validateMessages.types.email"),
      number: t("validateMessages.types.number"),
    },
    number: {
      range: t("validateMessages.number.range"),
    },
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const submitForm = async (values) => {
    const data = {
      ...values,
    };

    if (problemSolver && problemSolver.fullName) {
      data.fullName = problemSolver.fullName;
    }

    onSubmit(data);
    form.resetFields();
  };

  return (
    <Form
      {...layout}
      labelAlign="left"
      form={form}
      initialValues={{
        fullName: problemSolver?.fullName,
        email: problemSolver?.email,
      }}
      name="problemSolver"
      onFinish={submitForm}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="fullName"
        label={t("ProblemSolverForm.fullName")}
        rules={[
          {
            required: true,
          },
        ]}
        colon={false}
      >
        <Input disabled={disableAll} />
      </Form.Item>

      <Form.Item
        name="email"
        label={t("ProblemSolverForm.email")}
        colon={false}
        className="wrap-label"
        validateTrigger="onSubmit"
        rules={[
          {
            type: "email",
          },
        ]}
      >
        <Input disabled={disableAll} />
      </Form.Item>

      <Form.Item
        wrapperCol={{ ...layout.wrapperCol, offset: layout.labelCol.span }}
      >
        <Button
          data-testid="formSubmitButton"
          type="primary"
          htmlType="submit"
          disabled={disableAll}
        >
          {t("action.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProblemSolverForm;
