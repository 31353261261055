import React, { useEffect } from "react";
import useRequestsByCreatedAt from "../../../../../hooks/useRequestsByCreatedAt";
import RequestsOverviewPageHeader from "../../../../../pages/overview/request/RequestsOverviewPageHeader";
import ByCreatedAtTable from "./ByCreatedAtTable";

const ByCreatedAtOverview = ({ changes }) => {
  const {
    requests,
    reload,
    listLoading,
    updateRequest,
    deleteRequest,
    deleteLoading,
  } = useRequestsByCreatedAt();

  useEffect(() => {
    if (changes > 1) {
      reload();
    }
  }, [changes]);

  return (
    <>
      <RequestsOverviewPageHeader reload={reload} listLoading={listLoading} />
      <ByCreatedAtTable
        requests={requests}
        deleteRequest={deleteRequest}
        deleteLoading={deleteLoading}
        updateRequest={updateRequest}
      />
    </>
  );
};

export default ByCreatedAtOverview;
