/* eslint-disable no-template-curly-in-string */

import React from "react";

import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const CreateProblemSolverModalForm = ({ form, onSubmit }) => {
  const { t } = useTranslation();

  const validateMessages = {
    required: t("validateMessages.required"),
    types: {
      email: t("validateMessages.types.email"),
      number: t("validateMessages.types.number"),
    },
    number: {
      range: t("validateMessages.number.range"),
    },
  };

  return (
    <Form
      {...layout}
      labelAlign="left"
      form={form}
      name="problemSolver"
      validateMessages={validateMessages}
    >
      <Form.Item
        name="fullName"
        label={t("ProblemSolverForm.fullName")}
        rules={[
          {
            required: true,
          },
        ]}
        colon={false}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label={t("ProblemSolverForm.email")}
        colon={false}
        className="wrap-label"
        validateTrigger="onSubmit"
        rules={[
          {
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default CreateProblemSolverModalForm;
